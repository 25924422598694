import type { ReactNode } from 'react'
import { shouldShowFeature } from '~/config/features'
import { Header } from './Header'

interface ContainerProps {
  children: ReactNode
  className?: string
}

export function Container({ children, className = '' }: ContainerProps) {
  return (
    <div className="flex flex-col flex-1 sm:bg-midnight-100">
      {shouldShowFeature('nav') ? <Header /> : null}
      <div className="flex-1 sm:pb-12">
        <div
          className={`max-w-screen-sm lg:max-w-[792px] px-6 sm:px-14 py-8 sm:py-14 sm:mt-10 mx-auto bg-white sm:rounded-3xl ${className}`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
